import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardActions, CardContent, CardMedia, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import Header from './Header';
import Footer from './Footer';
function Career() {
  type resultProps = {
    id: string;
    title: string;
    imagelink: string;
    validuntil: string;
    readmorelink: string;
    description: string;
    description1: string;
    description2: string;
    date: string
  };
  
  function createData(
    title: string,
    link: string,
  ) {
    return { title, link };
  }
  
  const row2 = [
    createData('UNIQLO Store Partner', 'https://forms.gle/9pHxAcPkbMNwBAXV6'), 
    createData('INFORMATION OFFICER II - Resource Generation Office', './img/RGO(COS).pdf'),   
    createData('FACULTY HIRING - College of Engineering', './img/CE(COS).pdf'),  
    createData('FACULTY HIRING - National Service Training Program', './img/nstp.pdf'), 
    createData('19 PART-TIIME FACULTY HIRING - CHASS', './img/CHASS-Hiring of 19 Part time faculty 2024.pdf'),  
  ];
  // const [result, setResult] = useState<resultProps[]>([]);
  
  // useEffect(() => {
  //   const car = async () => {
  //     const data = await fetch("https://19.65.23.253/plmapi/career.php", {
  //       method: "GET"
  //     });
  //     const jsonData = await data.json();
  //     setResult(jsonData);
  //   };
  //   car();
  // }, []);

 return (
  <>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%"/> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '40px', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 96px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Career</Typography>
      </Box>
      <br/>
      {/* <Grid container>
        <Grid item xs={4}>
      <Card sx={{ elevation: 50,  maxWidth: 300, border: 0, padding: "8px 16px", orientation: "horizontal" }}>
          <CardMedia
            component="img"
            alt="wifi"
            height="300"
            image="./img/uniqlo.jpg"
          />
          <CardContent>
            <Typography gutterBottom variant="h6" component="div" align="center">
              UNIQLO Store Partner
            </Typography>
          </CardContent>
          <CardActions>
          <Typography align="center">
          Be next Level as UNIQLO Store Partner. <br/>If you are driven to exceed customer expectations, have a clear sense of mission, and are committed to inspire others, sign up now and build your future with us. <br/><br/>Click <a href="https://forms.gle/9pHxAcPkbMNwBAXV6">here</a> to apply.
        </Typography>
          </CardActions>
      </Card>
      </Grid>
      <Grid item xs={4}>
      <Card sx={{ elevation: 50,  maxWidth: 300, border: 0, padding: "8px 16px", orientation: "horizontal" }}>
          <CardMedia
            component="img"
            alt="wifi"
            height="300"
            image="./img/resource.jpg"
          />
          <CardContent>
            <Typography gutterBottom variant="h6" component="div" align="center">
              IINFORMATION OFFICER II<br/>Resource Generation Office
            </Typography>
          </CardContent>
          <CardActions>
          <Typography align="left">
          The Pamantasan ng Lungsod ng Maynila (PLM) Resource Generation Office (RGO) needs one (1) Information Officer II under Contract of Service.
          <br/><br/>Click <a href="./img/RGO(COS).pdf">here</a> for details.
        </Typography>
          </CardActions>
      </Card>
      </Grid>
      <Grid item xs={4}>
      <Card sx={{ elevation: 50,  maxWidth: 300, border: 0, padding: "8px 16px", orientation: "horizontal" }}>
          <CardMedia
            component="img"
            alt="wifi"
            height="300"
            image="./img/engineering.jpg"
          />
          <CardContent>
            <Typography gutterBottom variant="h6" component="div" align="center">
            FACULTY HIRING<br/>College of Engineering
            </Typography>
          </CardContent>
          <CardActions>
          <Typography align="left">
          The Pamantasan ng Lungsod ng Maynila (PLM) College of Engineering needs two (2) part-time faculty personnel.
          <br/><br/>Click <a href="./img/CE(COS).pdf">here</a> for details.
        </Typography>
          </CardActions>
      </Card>
      </Grid>
      </Grid>
      <br/>
      <hr/>
      <br/>
      <Grid container>
      <Grid item xs={4}>
      <Card sx={{ elevation: 50,  maxWidth: 300, border: 0, padding: "8px 16px", orientation: "horizontal" }}>
          <CardMedia
            component="img"
            alt="wifi"
            height="300"
            image="./img/nstp.jpg"
          />
          <CardContent>
            <Typography gutterBottom variant="h6" component="div" align="center">
            FACULTY HIRING<br/>National Service Training Program
            </Typography>
          </CardContent>
          <CardActions>
          <Typography align="left">
          The Pamantasan ng Lungsod ng Maynila (PLM) Office of the National Service Training Program needs two (2) part-time faculty personnel.
          <br/><br/>Click <a href="./img/nstp.pdf">here</a> for details.
        </Typography>
          </CardActions>
      </Card>
      </Grid>

      </Grid> */}

<TableContainer component={Paper} sx={{paddingLeft: "20%"}}>
      <Table sx={{ minWidth: 650, maxWidth: "50%", border: 1 }} aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="center"><Typography variant="h5">Opening</Typography></TableCell>
            <TableCell align="center"><Typography variant="h5">Link</Typography></TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {row2.map((row) => (
            <TableRow
              key={row.title}
              sx={{ border: 0 }}
            >
              <TableCell component="th" scope="row" align="left">
                {row.title}
              </TableCell>
              <TableCell align="center"><Typography><a href={row.link}>Download</a></Typography></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

      {/* {result && result.map((value) => {
         return (
           <Grid container key={value.id} justifyContent= "center">
             <Grid item xs={12} padding="16px 0px 16px 96px" alignContent="center">
               <Typography variant="h5" alignContent="center">{value.title}</Typography>
               <Typography alignItems="left">{value.description}</Typography>
               <Typography alignItems="left">{value.description1}</Typography>
               <Typography alignItems="left">{value.description2}</Typography>
             </Grid>
           </Grid>);
       })}      */}
      <br/>
      <br/>
      <br/>
      <Footer/>
       </>
 );
}

export default Career;
