import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardActions, CardContent, CardMedia, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Header from './Header';
import Footer from './Footer';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import EmailIcon from '@mui/icons-material/LocalOfferOutlined';


function Bids() {
  type resultProps = {
    id: string;
    title: string;
    imagelink: string;
    validuntil: string;
    readmorelink: string;
    description: string;
    description1: string;
    description2: string;
    date: string
  };
  
  const [result, setResult] = useState<resultProps[]>([]);
  
  // useEffect(() => {
  //   const car = async () => {
  //     const data = await fetch("https://19.65.23.253/plmapi/bids.php", {
  //       method: "GET"
  //     });
  //     const jsonData = await data.json();
  //     setResult(jsonData);
  //   };
  //   car();
  // }, []);

  const columns: GridColDef<(typeof rows)[number]>[] = [
    {
      field: 'title',
      headerName: 'Project Title',
      width: 250,
      editable: true,
    },
    {
      field: 'budget',
      headerName: 'ABC (Budget)',
      width: 250,
      editable: true,
    },
    {
      field: 'date',
      headerName: 'Published Date',
      width: 200,
      editable: true,
    },
  ];

  const rows = [
    { id: 1, title: 'Invitation to Bid: Purchase of Medical Equipment of the College of Medicine (CM)', budget: 'PhP1,639,264.00', date:'18 July 2024'},
    { id: 2, title: 'SUPPLEMENTAL/BID BULLETIN No. 12-G-2024 July 9, 2024 - SUPPLY, DELIVERY, AND INSTALLATION OF AIRCONDITIONING UNITS', budget: '', date:'09 July 2024'},
    { id: 3, title: 'SUPPLEMENTAL/BID BULLETIN No. 11-G-2024 July 2, 2024 - DIGITIZATION OF RECORDS (PHASE III)', budget: '', date:'02 July 2024'},
    { id: 4, title: 'SUPPLEMENTAL/BID BULLETIN No. 10-G-2024 June 19, 2024 - Information Technology Equipment', budget: '', date:'21 June 2024'},
    { id: 5, title: 'Invitation to Bid: Technical and Scientific Equipment', budget: 'PhP9,827,791.00', date:'20 June 2024'},
    { id: 6, title: 'Invitation to Bid: Supply, Delivery, and Installation of Airconditioning Units', budget: 'PhP14,456,517.00', date:'13 June 2024'},
    { id: 7, title: 'Supplemental/Bid Bulletin No. 09-G-2024 June 6, 2024: RENTAL OF PHOTOCOPYING MACHINE', budget: '', date:'07 June 2024'},
    { id: 8, title: 'Invitation to Bid: Digitization of Records (Phase III)', budget: 'PhP5,000,000.00', date:'31 May 2024'},
    { id: 9, title: 'Invitation to Bid: Information Technology Equipment – Rebidding (Lot No. 1)', budget: 'PhP2,234,302.56', date:'16 May 2024'},
    { id: 10, title: 'Invitation to Bid: Rental of Photocopying Machine', budget: 'PhP2,000,000.00', date:'15 May 2024'},
    { id: 11, title: 'Supplemental/Bid Bulletin No. 07-G-2024 April 5, 2024: Provision for Purchase of Cadavers - Rebidding', budget: '', date:'02 May 2024'},
    { id: 12, title: 'Supplemental/Bid Bulletin No. 08-G-2024 April 24, 2024: PROVISION FOR PURCHASE OF CADAVERS - REBIDDING', budget: '', date:'25 April 2024'},
    { id: 13, title: 'Supplemental/Bid Bulletin No. 06-G-2024 April 8, 2024: Voice Based Communication Solutions - Rebidding', budget: '', date:'08 April 2024'},
    { id: 14, title: 'Invitation to Bid: Provision for Purchase of Cadavers - Rebidding', budget: 'PhP1,056,000.00', date:'04 April 2024'},
    { id: 15, title: 'Supplemental/Bid Bulletin No. 05-G-2024 March 22, 2024: PLM Digitalization through Integrated Systems', budget: '', date:'26 March 2024'},
    { id: 16, title: 'Invitation to Bid: Voice Based Communication Solutions - Rebidding', budget: '', date:'15 March 2024'},
    { id: 17, title: 'Supplemental/Bid Bulletin No. 04-G-2024 March 7, 2024: Purchase of Medical Equipment', budget: '', date:'08 March 2024'},
    { id: 18, title: 'Invitation to Bid: PLM Digitalization through Integrated Systems (Multi-Year Contract)', budget: 'PhP60,000,000.00', date:'07 March 2024'},
    { id: 19, title: 'Invitation to Bid: Replacement of Main Feederline of Pride Hall Building', budget: 'PhP1,800,000.00', date:'22 February 2024'},
    { id: 20, title: 'Invitation to Bid: Purchase of Medical Equipment', budget: 'PhP1,522,334.00', date:'21 February 2024'},
  ];

  function createData(
    title: string,
    link: string,
  ) {
    return { title, link };
  }
  
  const row2 = [
    createData('2 lots Purchase of Multimedia Equipment', './img/RFQ - 2 lots Purchase of Multimedia Equipment.pdf'),    
    createData('5 Lots Tools and Materials for the Office of Public Affairs', './img/Tools and Materials for Opa.pdf'),    
    createData('Purchase of Supplies and Materials for University Library', './img/Supplies and Materials for Ul.pdf'),    
    createData('Purchase of Various Tools and Equipment', './img/Various Tools and Equipment.pdf'),    
    createData('Lot Purchase of CPT Laboratory Supplies for 3rd Quarter CY 2024', './img/Cpt Lab. Supplies 3rd Quarter.pdf'),    
    createData('Notice of Postponement_Internet Subscription for PLM.9.5.2024', './img/Notice of Postponement_Internet Subscription for PLM.9.5.2024.pdf'),    
    createData('Notice of Postponement_AC Units-Re-Bidding.9.5.2024', './img/Notice of Postponement_AC Units-Re-Bidding.9.5.2024.pdf'),
    createData('7 Lots Food Provision for Syllabus Revision and Writing Workshop Series Part 2', './img/RFQ No. 24-09-03-231  7 Lots Food Provision for Syllabus Revision and Writing Workshop Series Part 2.pdf'),
    createData('SUPPLEMENTAL/BID BULLETIN No. 19-G-2024 - Internet Subscription for PLM', './img/SBB 19-G-2024_Internet.pdf'),
    createData('SUPPLEMENTAL/BID BULLETIN No. 18-G-2024 - Supply, Delivery, and Installation of Airconditioning Units – Re-Biddin', './img/SBB 18-G-2024_Aircon-Re-Bidding.pdf'),
    createData('SUPPLEMENTAL/BID BULLETIN No. 17-G-2024 - Various Hardware Supplies and Material', './img/SBB 17-G-2024.pdf'),
    createData('RFQ No. 24-08-22-226 3 Lots Food Provision of Participants for the Conduct of Adjustment to College Life', './img/RFQ No. 24-08-22-226 3 Lots Food Provision of Participants for the Conduct of Adjustment to College Life.pdf'),
    createData('RFQ - 2 lots Purchase of CPT Medical Equipment', './img/RFQ - 2 lots Purchase of CPT Medical Equipment.pdf'),
    createData('Pre-Bid Conference Minutes of the 32nd PLM-BAC Meeting dated 22 August 2024 for the project- Various Hardware Supplies and Materials', './img/Pre Bid Minutes the 32nd PLMBAC Meeting  08.22.24.pdf'),
    createData('Supplemental/Bid: Supply, Delivery, and Installation of Airconditioning Units – Re-Bidding', './img/SBB 14-G-2024.pdf'),
    createData('Supplemental/Bid: Internet Subscription for PLM', './img/SBB 15-G-2024.pdf'),
    createData('Supplemental/Bid: Various Hardware Supplies and Materials', './img/SBB 16-G-2024.pdf'),
    createData('Notice of Postponement for Submission and opening of bids conference', './img/Notice of Postponement_Various Hardware.8.29.2024.pdf'),
    createData('Library Books', './img/BD_2024-G-18.Library Books.Revised.9.10.24.pdf'),
    createData('Internet Subscription', './img/BD_2024-G-22.Internet Subscription for PLM 2024.pdf'),
    createData('Airconditioning Units', './img/BD_2024-G-21.SDI of Airconditioning Units - Re-Bidding.pdf'),
    createData('Laboratory Supplies', './img/Laboratory Supplies.pdf'),
    createData('Cadave', './img/Cadave.pdf'),
    
  ];

  return (
  <>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%"/> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '40px', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Bids and Awards</Typography>
      </Box>
      <br/>

    <TableContainer component={Paper} sx={{paddingLeft: "20%"}}>
      <Table sx={{ minWidth: 650, maxWidth: "50%" }} aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="center"><Typography variant="h5">Bids Items</Typography></TableCell>
            <TableCell align="center"><Typography variant="h5">Link</Typography></TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {row2.map((row) => (
            <TableRow
              key={row.title}
              sx={{ border: 0 }}
            >
              <TableCell component="th" scope="row" align="left">
                {row.title}
              </TableCell>
              <TableCell align="center"><Typography><a href={row.link}>Download</a></Typography></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
      <br/>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: "0px 72px"}}>
        <Typography variant="h5">Annual Procurement Plan</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: "0px 72px", display: { xs: 'none', md: 'flex' } }}>
        <Typography>
        The Annual Procurement Plan (APP) reflects the university's procurement activities for a specific calendar year.
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: "0px 72px", display: { xs: 'none', md: 'flex' } }}>
        <Typography>
        The plan, along with other pertinent procurement-related documents, are publicly available on PLM's website for transparency and accountability, and as prescribed by Republic Act No. 9184 or the Government Procurement Reform Act.
        </Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: "0px 72px 0px 96px", display: { xs: 'none', md: 'flex' } }}>
        <Typography>
        <a href="./img/Approved_Annual_Procurement_Plan_for_CY_2024.pdf">Annual Procurement Plan for Calendar Year 2024</a>
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: "0px 72px 0px 96px", display: { xs: 'none', md: 'flex' } }}>
        <Typography>
        <a href="./img/PLM-Procurement_Monitoring_Report_as_of_October_31_2023.pdf">Procurement Monitoring Report (PMR) as of October 31, 2023</a>
        </Typography>
      </Box>
      <br/>
      <hr/>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: "0px 72px"}}>
        <Typography variant="h5">Project Monitoring Report</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: "0px 72px 0px 96px", display: { xs: 'none', md: 'flex' } }}>
        <Typography>
        <a href="./img/2024 Project Monitoring Report (PMR) as of June 30, 2024.pdf">2024 Project Monitoring Report (PMR) as of June 30, 2024</a>
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: "0px 72px 0px 96px", display: { xs: 'none', md: 'flex' } }}>
        <Typography>
        <a href="./img/Continuing Project Monitoring Report (PMR) as of June 30, 2024.pdf">Continuing Project Monitoring Report (PMR) as of June 30, 2024</a>
        </Typography>
      </Box>
      <br/>
      <hr/>
      <br/>

      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: "0px 72px"}}>
        <Typography variant="h5">Consolidated Updates of APP</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: "0px 72px 0px 96px", display: { xs: 'none', md: 'flex' } }}>
        <Typography>
        <a href="./img/Updating Continuing APP (January to June 2024).pdf">Updating Continuing APP (January to June 2024)</a>
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: "0px 72px 0px 96px", display: { xs: 'none', md: 'flex' } }}>
        <Typography>
        <a href="./img/Updated 2024 APP (January to June 2024).pdf">Updated 2024 APP (January to June 2024)</a>
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: "0px 72px 0px 96px", display: { xs: 'none', md: 'flex' } }}>
        <Typography>
        <a href="./img/Consolidated_Updates_of_2023_APP_July-Dec_2023_rotated_1.pdf">Consolidated Updates of 2023 APP (July-December 2023)</a>
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: "0px 72px 0px 96px", display: { xs: 'none', md: 'flex' } }}>
        <Typography>
        <a href="./img/Consolidated_Updates_of_2023_APP_July-Dec_2023_rotated_2.pdf">Consolidated Updates of 2023 Supplemental APP (July-December 2023)</a>
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: "0px 72px 0px 96px", display: { xs: 'none', md: 'flex' } }}>
        <Typography>
        <a href="./img/Consolidated_Updates_of_2023_APP_July-Dec_2023_rotated_3.pdf">Procurement Monitoring Report as of December 2023</a>
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: "0px 72px 0px 96px", display: { xs: 'none', md: 'flex' } }}>
        <Typography>
        <a href="./img/Consolidated_Updates_and_Supplemental_APP_as_of_October_31_2023.pdf">Consolidated and Supplemental 2023 Annual Procurement</a>
        </Typography>
      </Box>
      <br/>
      <hr/>
      <br/>

      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: "0px 72px"}}>
        <Typography variant="h5">Competitive Bidding</Typography>
      </Box>

      <Box sx={{width: "70%", padding: "0px 72px"}}>
        <Box sx={{ height: 'auto', width: '70%'}}>
          <DataGrid
            rows={rows}
            columns={columns}
            disableRowSelectionOnClick
            hideFooter
          />
        </Box>
    </Box>

      
      
      {/* {result && result.map((value) => {
         return (
           <Grid container key={value.id} justifyContent= "center">
             <Grid item xs={12} padding="16px 0px 16px 96px" alignContent="center">
               <Typography variant="h5" alignContent="center">{value.title}</Typography>
               <Typography alignItems="left">{value.description}</Typography>
               <Typography alignItems="left">{value.description1}</Typography>
               <Typography alignItems="left">{value.description2}</Typography>
             </Grid>
           </Grid>);
       })}      */}
      <br/>
      <br/>
      <br/>
      <Footer/>
       </>
 );
}

export default Bids;